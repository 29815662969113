<template>
  <div class="main-container">
    <!-- 引入侧边栏 -->
    <Sidebar />

    <!-- 主体内容 -->
    <div class="main-content">
      <h1>Enrichment Page</h1>
      <p>人物丰富功能页面 输入人物社交账号获取人物信息如果需要请联系管理员.</p>
      <p>Character enrichment function page Enter the character's social account to obtain character information. If necessary, please contact the administrator</p>
    </div>
  </div>
</template>

<script>
import Sidebar from "./Sidebar.vue";

export default {
  name: "Enrichment",
  components: {
    Sidebar,
  },
};
</script>

<style scoped>
/* 添加样式 */
.main-container {
  display: flex;
  height: 100vh; /* 全屏高度 */
}

.main-content {
  flex: 1; /* 主内容占满剩余空间 */
  padding: 20px;
  background: #f9f9f9; /* 主体背景色 */
}
</style>
