import { render, staticRenderFns } from "./loginRegister.vue?vue&type=template&id=68b6db1e&scoped=true"
import script from "./loginRegister.vue?vue&type=script&lang=js"
export * from "./loginRegister.vue?vue&type=script&lang=js"
import style0 from "./loginRegister.vue?vue&type=style&index=0&id=68b6db1e&prod&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68b6db1e",
  null
  
)

export default component.exports