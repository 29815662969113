<template>
  <aside class="sidebar">
    <div class="sidebar-header">@LiuYang</div>
    <ul class="sidebar-menu">
      <li>
        <router-link to="/home" class="menu-item">Home</router-link>
      </li>
      <li>
        <router-link to="/search" class="menu-item">Person Search</router-link>
      </li>
      <li>
        <router-link to="/enrichment" class="menu-item">Person Enrichment</router-link>
      </li>

    </ul>
    <div class="sidebar-footer">
      <!-- 显示当前用户邮箱 -->
      <div class="user-info">
        <p>{{ userEmail }}</p>
      </div>
      <button class="logout-btn" @click="logout">Logout</button>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    userEmail: {
      type: String,
      required: true,
    },
  },
  methods: {
    logout() {
      this.$emit("logout");
    },
  },
};
</script>
<style scoped>
/* 主容器样式 */
.main-container {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

/* 侧边栏样式 */
.sidebar {
  width: 250px;
  background: linear-gradient(180deg, #5D44E7, #6a0dad); /* 渐变色背景 */
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px 0 0 10px; /* 圆角效果 */
}

/* 侧边栏头部样式 */
.sidebar-header {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #f9f9f9; /* 明亮字体颜色 */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3); /* 轻微阴影 */
}

/* 菜单列表样式 */
.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu li {
  margin: 15px 0;
}

/* 菜单项样式 */
.menu-item {
  display: block;
  text-align: center;
  background-color: #ffffff; /* 白色背景 */
  color: #5D44E7; /* 主色文字 */
  text-decoration: none;
  padding: 15px 10px;
  border-radius: 8px;
  font-size: 1.1em;
  font-weight: bold;
  transition: all 0.3s ease; /* 平滑过渡 */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* 轻微阴影 */
}

/* 悬停效果 */
.menu-item:hover {
  background-color: #5D44E7; /* 悬停背景 */
  color: #fff; /* 悬停文字颜色 */
  transform: translateY(-3px); /* 提升效果 */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); /* 增加阴影 */
}

/* 底部样式 */
.sidebar-footer {
  margin-top: auto;
  text-align: center;
}

.user-info {
  margin-bottom: 15px;
  font-size: 1em;
  color: #f0f0f0; /* 明亮字体颜色 */
  word-wrap: break-word;
  text-align: center;
}

/* 按钮样式 */
.logout-btn {
  background: linear-gradient(45deg, #ff4500, #e63900); /* 渐变色按钮 */
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.3s ease;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* 按钮阴影 */
}

/* 按钮悬停效果 */
.logout-btn:hover {
  background: linear-gradient(45deg, #e63900, #c12700);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* 增强阴影 */
}

/* 主内容样式 */
.main-content {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 0 10px 10px 0; /* 圆角右边框 */
}
</style>
