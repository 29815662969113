// main.js

import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';

// 配置 axios
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

// 动态设置 axios 的 baseURL
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

// 请求拦截器，自动添加 Authorization 头部
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
