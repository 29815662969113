import Vue from "vue";
import Router from "vue-router";
import LoginRegister from "../views/loginRegister.vue";
import Main from "../views/main.vue";
import Search from "../views/search.vue";
import Enrichment from "../views/enrichiment.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "LoginRegister",
      component: LoginRegister,
    },
    {
      path: "/home",
      name: "Main",
      component: Main,
      meta: { requiresAuth: true },
    },
    {
      path: "/search",
      name: "Search",
      component: Search,
      meta: { requiresAuth: true }, // 添加权限验证
    },{
      path: "/enrichment",
      name: "enrichment",
      component: Enrichment,
      meta: { requiresAuth: true }, // 添加权限验证
    },

    {
      path: "*",
      redirect: "/home",
    },
  ],
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem("token"); // 检查是否已登录
  if (to.matched.some((record) => record.meta.requiresAuth) && !isLoggedIn) {
    alert("请先登录！");
    next("/"); // 未登录时跳转到登录页面
  } else {
    next(); // 继续导航
  }
});

export default router;
