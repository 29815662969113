<template>
  <div class="login-register">
    <div class="contain">
      <div class="big-box" :class="{ active: isLogin }">
        <!-- 登录界面 -->
        <div class="big-contain" key="bigContainLogin" v-if="isLogin">
          <div class="btitle">账户登录login</div>
          <div class="bform">
  <input
    type="email"
    placeholder="邮箱email"
    v-model="form.useremail"
    :class="{ error: emailError }"
  />
  <span class="errTips" v-if="emailError">*error邮箱不存在 *</span>
  <input
    type="password"
    placeholder="密码password"
    v-model="form.userpwd"
    :class="{ error: passwordError }"
  />
  <span class="errTips" v-if="passwordError">* error密码错误 *</span>
</div>

          <button class="bbutton" @click="login">登录login</button>
        </div>

        <!-- 注册界面 -->
        <div class="big-contain" key="bigContainRegister" v-else>
          <div class="btitle">创建账户creataccount</div>
          <div class="bform">
            <input
              type="text"
              placeholder="用户名username(3-15个字符)"
              v-model="form.username"
            />
            <span class="errTips" v-if="usernameError">* error用户名长度不符合要求 *</span>
            <input
              type="email"
              placeholder="邮箱email"
              v-model="form.useremail"
            />
            <span class="errTips" v-if="emailError">* error邮箱格式错误 *</span>
            <input
              type="password"
              placeholder="密码password(6-20个字符)"
              v-model="form.userpwd"
            />
            <span class="errTips" v-if="passwordError">* error密码长度不符合要求 *</span>
          </div>
          <button class="bbutton" @click="register">注册register</button>
        </div>
      </div>

      <div class="small-box" :class="{ active: isLogin }">
        <div
          class="small-contain"
          key="smallContainRegister"
          v-if="isLogin"
        >
          <div class="stitle">你好，朋友!</div>
          <button class="sbutton" @click="changeType">注册register</button>
        </div>
        <div
          class="small-contain"
          key="smallContainLogin"
          v-else
        >
          <div class="stitle">欢迎回来!</div>
          <button class="sbutton" @click="changeType">登录login</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLogin: false,
      emailError: false,
      passwordError: false,
      usernameError: false,
      form: {
        username: "",
        useremail: "",
        userpwd: "",
      },
    };
  },
  methods: {
    changeType() {
      this.isLogin = !this.isLogin;
      this.resetForm();
    },
    resetForm() {
      this.form.username = "";
      this.form.useremail = "";
      this.form.userpwd = "";
      this.emailError = false;
      this.passwordError = false;
      this.usernameError = false;
    },
    validateForm() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.usernameError =
        this.form.username.length < 3 || this.form.username.length > 15;
      this.emailError = !emailRegex.test(this.form.useremail);
      this.passwordError =
        this.form.userpwd.length < 6 || this.form.userpwd.length > 20;
      return !this.usernameError && !this.emailError && !this.passwordError;
    },
    register() {
      if (!this.validateForm()) return;

      this.$axios
        .post("/user/register", {
          username: this.form.username,
          email: this.form.useremail,
          password: this.form.userpwd,
        })
        .then((res) => {
          if (res.data.status === 0) {
            alert(res.data.message); // 显示注册成功消息
            this.changeType(); // 切换到登录界面
          }
        })
        .catch((err) => {
  let message = "注册失败，请稍后重试！";

  if (err.response && err.response.data && err.response.data.message) {
    message = err.response.data.message;

    if (message.includes('用户名')) {
      this.usernameError = true;
    }
    if (message.includes('邮箱')) {
      this.emailError = true;
    }
  } else if (err.message) {
    message = err.message;
  }

  alert(message); // 显示错误消息
});

    },
    login() {
  if (!this.form.useremail || !this.form.userpwd) {
    alert("邮箱和密码不能为空！");
    return;
  }

  console.log('Login Request Payload:', {
    email: this.form.useremail,
    password: this.form.userpwd,
  });

  this.$axios
    .post("/user/login", {
      email: this.form.useremail,
      password: this.form.userpwd,
    })
    .then((res) => {
      console.log('Login Success:', res.data);
      if (res.data.status === 0) {
        alert(res.data.message); // 显示登录成功消息
        localStorage.setItem('token', res.data.token); // 存储登录 token
        this.$router.push('/home'); // 跳转到主页
      }
    })
    .catch((err) => {
      console.error('Login Error Response:', err.response || err);

      let message = "登录失败，请稍后重试！";

      if (err.response && err.response.data && err.response.data.message) {
        message = err.response.data.message;

        if (message.includes('用户名或密码错误')) {
          this.emailError = true;
          this.passwordError = true;
        }
      }

      alert(message);
    });
}
,
  },
};
</script>



<style scoped="scoped">
	.login-register{
		width: 100vw;
		height: 100vh;
		box-sizing: border-box;
	}
  input.error {
  border: 1px solid red;
}


	.contain{
		width: 60%;
		height: 60%;
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		background-color: #fff;
		border-radius: 20px;
		box-shadow: 0 0 3px #f0f0f0,
					0 0 6px #f0f0f0;
	}
	.big-box{
		width: 70%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 30%;
		transform: translateX(0%);
		transition: all 1s;
	}
	.big-contain{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.btitle{
		font-size: 1.5em;
		font-weight: bold;
		color: rgb(57,167,176);
	}
	.bform{
		width: 100%;
		height: 40%;
		padding: 2em 0;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
	}
	.bform .errTips{
		display: block;
		width: 50%;
		text-align: left;
		color: red;
		font-size: 0.7em;
		margin-left: 1em;
	}
	.bform input{
		width: 50%;
		height: 30px;
		border: none;
		outline: none;
		border-radius: 10px;
		padding-left: 2em;
		background-color: #f0f0f0;
	}
	.bbutton{
		width: 20%;
		height: 40px;
		border-radius: 24px;
		border: none;
		outline: none;
		background-color: rgb(57,167,176);
		color: #fff;
		font-size: 0.9em;
		cursor: pointer;
	}
	.small-box{
		width: 30%;
		height: 100%;
		background: linear-gradient(135deg,rgb(57,167,176),rgb(56,183,145));
		position: absolute;
		top: 0;
		left: 0;
		transform: translateX(0%);
		transition: all 1s;
		border-top-left-radius: inherit;
		border-bottom-left-radius: inherit;
	}
	.small-contain{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.stitle{
		font-size: 1.5em;
		font-weight: bold;
		color: #fff;
	}
	.scontent{
		font-size: 0.8em;
		color: #fff;
		text-align: center;
		padding: 2em 4em;
		line-height: 1.7em;
	}
	.sbutton{
		width: 60%;
		height: 40px;
		border-radius: 24px;
		border: 1px solid #fff;
		outline: none;
		background-color: transparent;
		color: #fff;
		font-size: 0.9em;
		cursor: pointer;
	}

	.big-box.active{
		left: 0;
		transition: all 0.5s;
	}
	.small-box.active{
		left: 100%;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-top-right-radius: inherit;
		border-bottom-right-radius: inherit;
		transform: translateX(-100%);
		transition: all 1s;
	}
</style>
