<template>
  <div class="main-container">
    <!-- 引入侧边栏 -->
    <Sidebar :userEmail="userEmail" @logout="logout" />

    <!-- 主体部分 -->
    <div class="main-content">
      <div class="query-builder-container">
        <div class="header">
          <h1 class="title">Build Your Query</h1>
        </div>
        <div class="controls">
          <select v-model="selectedType" class="select-control">
            <option disabled value="">Select type</option>
            <option value="person">Person</option>
          </select>
          <select v-model="selectedDataset" class="select-control">
            <option disabled value="">Select dataset</option>
            <option value="all">All</option>
          </select>
        </div>
        <div class="query-builder">
          <div v-for="(rule, index) in rules" :key="index" class="rule-group">
            <div class="rule-container">
              <select v-if="index !== 0" v-model="rule.logic" class="logic-selector">
                <option value="AND">AND</option>
                <option value="OR">OR</option>
              </select>
              <select v-model="rule.field" class="field-select">
                <option disabled value="">Select Field</option>
                <option v-for="field in availableFields" :key="field.name" :value="field.name">
                  {{ field.name }}
                </option>
              </select>
              <select v-model="rule.operator" class="operator-select">
                <option disabled value="">Select Operator</option>
                <!-- 修改可选链为安全逻辑 -->
                <option
                  v-for="operator in (availableFields.find(f => f.name === rule.field) || {}).conditions || []"
                  :key="operator"
                  :value="operator"
                >
                  {{ operator }}
                </option>
              </select>
              <input
                v-model="rule.value"
                class="value-input"
                type="text"
                placeholder="Value"
                :disabled="rule.operator === 'null' || rule.operator === 'notNull'"
              />
              <button @click="removeRule(index)" class="remove-btn">Remove</button>
            </div>
          </div>
          <div class="add-rule-container">
            <button class="add-rule-btn" @click="addRule">Add Rule</button>
          </div>
        </div>
        <div class="limit-container">
          <p>输入要搜索的数据条数建议一次10条以内以免等待时间过长</p>
          <label for="limit-input"
            >Enter the number of data to search. It is recommended to enter no more than 10 at a time to avoid waiting
            too long:</label
          >
          <input
            id="limit-input"
            type="number"
            v-model="limit"
            class="limit-input"
            min="1"
            placeholder="Enter limit"
          />
        </div>
        <button class="search-btn" @click="generateSQL" :disabled="isLoading">
          {{ isLoading ? "Loading..." : "Search" }}
        </button>
        <!-- 加载提示信息 -->
        <div v-if="isLoading" class="loading-message">
          Data fetching in progress, please wait and avoid repeated clicks.数据正在获取中，请等待并避免重复点击。
        </div>

        <!-- 下载链接部分 -->
        <div class="download-link-container">
          <h3>Your download will be ready here:</h3>
          <div v-if="downloadLink">
            <a :href="downloadLink" target="_blank" download
              >Click here to download the results 点这里下载数据</a
            >
          </div>
          <div v-else>
            <p>No file available for download yet.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "./Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      userEmail: "",
      selectedType: "",
      selectedDataset: "",
      rules: [{ field: "", operator: "", value: "", logic: "AND" }],
      limit: "",
      availableFields: [
        {
          name: "birth_year",
          conditions: ["=", "!=", "<", ">", "<=", ">=", "null", "notNull"],
        },
        {
          name: "education.school.name",
          conditions: ["=", "!=",  "null", "notNull"],
        },
        {
          name: "inferred_salary",
          conditions: ["=", "!=", "<", ">", "<=", ">=", "range"],
        },
        { name: "sex", conditions: ["=", "!=", "null", "notNull", "in", "notIn"] },
        { name: "industry", conditions: ["=", "!=",  "null", "notNull"] },
        { name: "location_country", conditions: ["=", "!=",  "null", "notNull"] },
        { name: "location_region", conditions: ["=", "!=",  "null", "notNull"] },
      ],
      downloadLink: "",
      isLoading: false,
    };
  },
  mounted() {
    this.fetchUserEmail();
  },
  methods: {
    async fetchUserEmail() {
      try {
        const response = await this.$axios.get("/user/info");
        if (response.data.status === 0) {
          this.userEmail = response.data.email;
        } else {
          console.error("Failed to fetch user email.");
        }
      } catch (error) {
        console.error("Error fetching user email:", error);
        if (error.response && error.response.status === 401) {
          alert("您的会话已过期，请重新登录。");
          this.$router.push("/login");
        }
      }
    },
    addRule() {
      this.rules.push({ field: "", operator: "", value: "", logic: "AND" });
    },
    removeRule(index) {
      this.rules.splice(index, 1);
    },
    async generateSQL() {
  if (this.isLoading) return;

  this.isLoading = true;
  try {
    let excludedIds = [];
    const response = await this.$axios.get('/user/excluded_ids');
    if (response.data.status === 0) {
      excludedIds = response.data.ids;
    }

    let whereClauses = this.rules
      .map((rule, index) => {
        let condition = '';
        if (rule.operator === 'null' || rule.operator === 'notNull') {
          condition = `${rule.field} IS ${rule.operator === 'notNull' ? 'NOT NULL' : 'NULL'}`;
        } else if (rule.operator === 'in' || rule.operator === 'notIn') {
          const values = rule.value
            ? rule.value.split(',').map((v) => `'${v.trim()}'`).join(', ')
            : '';
          condition = `${rule.field} ${rule.operator === 'in' ? 'IN' : 'NOT IN'} (${values})`;
        } else {
          condition = `${rule.field} ${rule.operator} '${rule.value.replace(/'/g, "\\'")}'`; // 处理特殊字符
        }
        return index === 0 ? condition : `${rule.logic} ${condition}`;
      })
      .join(' ');

    if (excludedIds.length > 0) {
      const exclusionCondition = `id NOT IN (${excludedIds.map((id) => `'${id}'`).join(', ')})`;
      whereClauses += whereClauses ? ` AND ${exclusionCondition}` : exclusionCondition;
    }

    const sqlQuery = `SELECT * FROM person WHERE ${whereClauses}`;
    console.log('Generated SQL Query:', sqlQuery);

    const payload = {
      apiKey: localStorage.getItem('activeKey'),
      sqlQuery,
      size: parseInt(this.limit) || 10,
      dataset: this.selectedDataset || 'all',
    };

    console.log('Payload being sent:', payload);

    const searchResponse = await this.$axios.post('/search', payload);
    if (searchResponse.data.status === 0) {
      const zipPath = searchResponse.data.zipPath;
      if (zipPath) {
        this.downloadLink = `${this.$axios.defaults.baseURL.replace('/api', '')}${zipPath}`;
        alert('success搜索成功，您的结果已准备好下载。');
      } else {
        alert('搜索成功，但无法生成下载链接。');
      }
    } else {
      alert('搜索失败：' + searchResponse.data.message);
    }
  } catch (error) {
    console.error('Error during search:', error);
    alert('error:请检查参数的逻辑或者内容是否正确，Please check whether the logic or content of the parameters is correct.');
  } finally {
    this.isLoading = false;
  }
}
,
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/");
    },
  },
};
</script>



<style scoped>
/* 样式保持不变 */
.main-container {
  display: flex;
  height: 100vh;
}

.main-content {
  flex: 1;
  padding: 20px;
  background: #f9f9f9;
}

.query-builder-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.title {
  font-size: 1.5em;
  font-weight: bold;
}

.info-icon svg {
  stroke: #6a0dad;
}

.controls {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.select-control {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.query-builder {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rule-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logic-selector {
  width: 80px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.field-select,
.operator-select,
.value-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1;
}

.limit-container {
  margin-top: 20px;
}

.limit-input {
  margin-left: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100px;
}

.search-btn {
  margin-top: 20px;
  width: 100%;
  padding: 15px;
  background: #6a0dad;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
}

.download-link-container {
  margin-top: 20px;
  background: #e9f5e9;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
}

.download-link-container a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.download-link-container a:hover {
  text-decoration: underline;
}

/* 新增加载提示样式 */
.loading-message {
  color: #d9534f;
  font-size: 0.9em;
  margin-top: 10px;
}
</style>
