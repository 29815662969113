<template>
  <div class="main-container">
    <!-- 引入侧边栏 -->
    <Sidebar :userEmail="userEmail" @logout="logout" />

    <!-- 主体部分 -->
    <div class="main-content">
      <!-- 顶部导航栏 -->
      <header class="header">
        <h1>API KEY</h1>
        <button class="newkey-btn" @click="createKey">Add New Key</button>
      </header>

      <!-- Active 和 Archived Tabs -->
      <div class="tabs">
        <button
          class="tab-btn"
          :class="{ active: currentTab === 'active' }"
          @click="currentTab = 'active'"
        >
          Active
        </button>
        <button
          class="tab-btn"
          :class="{ active: currentTab === 'archived' }"
          @click="currentTab = 'archived'"
        >
          Archived
        </button>
      </div>

      <!-- Active Keys -->
      <div v-if="currentTab === 'active'" class="key-info">
        <div v-if="activeKey">
          <div class="key-display">
            <span>Key:</span>
            <input type="text" :value="activeKey" readonly />
            <button @click="copyKey(activeKey)">Copy</button>
          </div>
          <div class="usage-details">
            <div>
              <h3>People Enrichment API</h3>
              <p>{{ enrichmentRemaining }} 剩余 / {{ enrichmentTotal }}</p>
              <div class="progress-bar">
                <div
                  class="progress"
                  :style="{ width: (enrichmentRemaining / enrichmentTotal) * 100 + '%' }"
                ></div>
              </div>
            </div>
            <div>
              <h3>People Search API</h3>
              <p>{{ searchRemaining }} 剩余 / {{ searchTotal }}</p>
              <div class="progress-bar">
                <div
                  class="progress"
                  :style="{ width: (searchRemaining / searchTotal) * 100 + '%' }"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <p v-else>没有积极的密钥</p>
      </div>

      <!-- Archived Keys -->
      <div v-if="currentTab === 'archived'" class="key-info">
        <div v-for="key in archivedKeys" :key="key.key" class="key-card">
          <div class="key-header">
            <span class="key-value">{{ key.key }}</span>
          </div>
          <div class="key-details">
            <p>Enrichment Used: <span>{{ key.enrichmentUsed }}</span> / <span>{{ key.enrichmentLimit }}</span></p>
            <p>Search Used: <span>{{ key.searchUsed }}</span> / <span>{{ key.searchLimit }}</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "./Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      currentTab: "active",
      activeKey: "",
      archivedKeys: [],
      enrichmentRemaining: 0,
      enrichmentTotal: 100,
      searchRemaining: 0,
      searchTotal: 100,
      userEmail: "",
    };
  },
  methods: {
    async loadKeys() {
      try {
        const response = await this.$axios.get("/user/keys");

        if (response.data.status === 0) {
          this.activeKey = response.data.activeKey || "";
          localStorage.setItem("activeKey", this.activeKey);
          this.archivedKeys = response.data.archivedKeys.map((key) => ({
            key: key.key,
            enrichmentUsed: key.enrichmentUsed || 0,
            enrichmentLimit: key.enrichmentLimit || 100,
            searchUsed: key.searchUsed || 0,
            searchLimit: key.searchLimit || 100,
          }));
          this.enrichmentRemaining = response.data.enrichmentRemaining || 0;
          this.searchRemaining = response.data.searchRemaining || 0;
        } else {
          alert("Failed to load API keys. Please refresh the page.");
        }
      } catch (error) {
        console.error("Error loading keys:", error);
        const message = error.response && error.response.data && error.response.data.message;
        alert(message || "An error occurred while loading API keys.");
      }
    },
    async fetchUserInfo() {
      try {
        const response = await this.$axios.get("/user/info");
        if (response.data.status === 0) {
          this.userEmail = response.data.email;
        } else {
          console.error("Failed to fetch user info");
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
        const message = error.response && error.response.data && error.response.data.message;
        alert(message || "An error occurred while fetching user info.");
      }
    },
    async createKey() {
      const password = prompt("Enter your password to create a new API key:");
      if (!password) {
        alert("Password is required to generate a new key!");
        return;
      }

      try {
        const response = await this.$axios.post("/user/newkey", { password });

        if (response.data.status === 0) {
          alert("New API Key generated successfully!");
          await this.loadKeys();
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        const message = error.response && error.response.data && error.response.data.message;
        alert(message || "Failed to create a new API Key. Please try again!");
      }
    },
    copyKey(key) {
      try {
        const textArea = document.createElement("textarea");
        textArea.value = key;
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        alert("Key copied to clipboard!");
      } catch (err) {
        console.error("Failed to copy key:", err);
        alert("Failed to copy key. Please try again!");
      }
    },
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/");
    },
  },
  async mounted() {
    await this.loadKeys();
    await this.fetchUserInfo();
  },
};
</script>




<style scoped>
.main-container {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.sidebar {
  width: 250px;
  background-color: #5D44E7;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar-header {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
}

.sidebar-menu li {
  margin: 10px 0;
}

.menu-item {
  display: block;
  background-color: #5D44E7;
  color: #fff;
  text-align: center;
  text-decoration: none;
  padding: 15px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  background-color: #5D44E7;
}

.sidebar-footer {
  margin-top: auto;
  text-align: center;
}
.key-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.2s;
}

.key-card:hover {
  transform: translateY(-5px);
}

.key-header {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #5D44E7;
}

.key-value {
  word-break: break-all;
  display: inline-block;
  font-family: monospace;
  font-size: 0.9em;
}

.key-details {
  margin-top: 10px;
  color: #555;
}

.key-details p {
  margin: 5px 0;
  font-size: 0.95em;
}

.key-details span {
  font-weight: bold;
  color: #5D44E7;
}

.user-info {
  margin-bottom: 15px;
  font-size: 0.9em;
  color: #fff;
  word-wrap: break-word;
  text-align: center;
}

.logout-btn {
  background-color: #ff4500;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.logout-btn:hover {
  background-color: #e63900;
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.newkey-btn {
  background-color: #5D44E7;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab-btn {
  padding: 10px 20px;
  border: none;
  background-color: #f4f4f4;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab-btn.active {
  background-color: #5D44E7;
  color: #fff;
}

.key-info {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.key-display {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.key-display input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.key-display button {
  padding: 10px;
  background-color: #5D44E7;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.usage-details {
  display: grid;
  gap: 20px;
}

.progress-bar {
  background-color: #f0f0f0;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: #5D44E7;
}

.archived-key {
  background-color: #f4f4f4;
  margin: 10px 0;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
</style>
